import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import popup from '../European/assets/images/popup.webp';
const HomeModal = () => {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <Modal show={show} onHide={handleClose} size='lg' className='bg-transparent' centered>
      <Modal.Header closeButton className='p-0 m-0 bg-transparent border-0 popup-header'></Modal.Header>
      <Modal.Body className='bg-transparent p-0'>
        <img className='img-fluid' src={popup} alt='modal' />
      </Modal.Body>
    </Modal>
  );
};

export default HomeModal;

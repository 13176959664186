import Aos from "aos";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { client_name } from "../../../Helpers/request";
import "./assets/css/home.css";
import BannerSlider from "./Bannerslider";
import ExploreCollection from "./ExploreCollection";
import RingBuilder from "./RingBuilder";
import ShopDiamondShape from "./ShopDiamondShape";
import ImageSection from "./ImageSection";
import VisitShowroom from "./VisitShowroom";
import RepairSection from "./RepairSection";
import Insta from "./Insta";
import Newsletter from "./Newsletter";
import Testimonial from "./Testimonial";
import FAQ from "./faq";
import HomeModal from "./HomeModal";
const Home = () => {
  const metaDetails = useSelector((state) => state.persistedReducer.meta.meta);

  useEffect(() => {
    Aos.init({
      duration: 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails?.title}</title>
        <meta name="description" content={metaDetails?.description}></meta>
        <meta name="keywords" content={metaDetails?.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails?.title} />
        <meta property="og:description" content={metaDetails?.description} />
        <meta property="og:url" content={metaDetails?.url} />
        <meta property="og:site_name" content={client_name?.c_name} />
      </Helmet>

      {/* <BannerSlider />
        <ExploreCollection />
        <RingBuilder />
        <ShopDiamondShape />
        <ImageSection />
        <VisitShowroom />
        <RepairSection />
        <FAQ />
        <Testimonial />
        <Insta />
        <Newsletter /> */}

      {/* new sequence */}
      <BannerSlider />
      <ImageSection />
      <RingBuilder />
      <ShopDiamondShape />
      <ExploreCollection />
      <VisitShowroom />
      <RepairSection />
      <Testimonial />
      <Insta />
      <Newsletter />

      <HomeModal />

      {/* 
        <Ringbuilderhome />
        <RingStyle />
        <ShopDiamondShape />
        <Gemstones />
        <Virtualappointment />
        <Customer />
        <Insta />
        <SignupTo /> */}
    </>
  );
};

export default Home;
